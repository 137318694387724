import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import MainTemplate from 'components/templates/MainTemplate';
import { title, heading } from 'constants/meta';
import Link from 'components/atoms/Link';
import { Ul, Li } from 'components/atoms/List';

const Title = styled.h1`
  font-weight: normal;
`;

const AboutPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.about}</title>
      <meta name="keywords" content="" />
      <meta name="description" content="" />
    </Helmet>
    <Title>{heading.about}</Title>
    <p>
      История компании «Золотая Камея» началась в 2005 году. За годы работы мы обрели
      друзей-партнёров по всей России. Сейчас с нами сотрудничают более полутора тысяч ювелирных
      производств и мастерских по всей России - от Сахалина до Калининграда. Компания «Золотая
      Камея» отправляет через Почту России и компании экспресс-доставки до 30 посылок - ЕЖЕДНЕВНО.
    </p>
    <p>
      Формула работы: сегодня мы принимаем заказ, завтра этот заказ уже на почте, в транспортной
      компании.
    </p>
    <p>
      Оплата самыми удобными для клиента способами: наложенный платёж, безналичная оплата на
      расчётный счёт, либо оплата банковской картой.
    </p>
    <Ul>
      <Li>
        <Link to="/contacts">Наши контакты и реквизиты</Link>
      </Li>
      <Li>
        <Link to="/contacts/team">Наша команда</Link>
      </Li>
      <Li>
        <Link to="/contacts/predstaviteli-v-regionah">Наши представители в регионах</Link>
      </Li>
      <Li>
        <Link to="/news">Наши новости</Link>
      </Li>
    </Ul>
  </MainTemplate>
);

export default AboutPage;
